import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { User } from '@shared/models/interfaces/user.interface';

@Injectable({
    providedIn: 'root',
})
export class AppsflyerService {
    init() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.text = `
        !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
        (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
        t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
        o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
        p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","pba",{pba: {webAppId: "${environment.APPS_FLYER_KEY}"}});
        `;
        document.head.appendChild(script);
    }

    trackUser(user: User) {
        const scriptContent = `
        AF('pba', 'setCustomerUserId', '${user.id}');
        `;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = scriptContent;
        document.body.appendChild(script);
    }
}
